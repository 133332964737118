.boxes-chooser {
    @apply overflow-hidden;

    scroll-padding-top: 0;
    scroll-padding-bottom: 100vh;

    &__boxes-swiper {
        .swiper-slide {
            height: auto !important;

            .bottom-content {
                @apply opacity-0 duration-300 transition-all pointer-events-none;

                @media screen and (max-width: 768px) {
                    @apply h-full;
                }
            }

            .image-wrap {
                @apply opacity-40 min-h-[200px] max-h-[200px] flex items-center;

                img {
                    @apply transition-all duration-300 ease-in-out;

                    filter: blur(2px);

                    transform: scale(0.8);
                    margin: auto;
                }

                @media screen and (max-width: 1600px) {
                    @apply min-h-[180px] max-h-[180px];
                }

                @media screen and (max-width: 1280px) {
                    @apply min-h-[180px] max-h-[180px];
                }

                @media screen and (max-width: 801px) {
                    @apply min-h-[185px] max-h-[185px];
                }

                @media screen and (max-width: 760px) {
                    @apply min-h-[155px] max-h-[155px];
                }

                @media screen and (max-width: 550px) {
                    @apply min-h-[125px] max-h-[125px];
                }

                @media screen and (max-width: 450px) {
                    @apply min-h-[100px] max-h-[100px];
                }

                @media screen and (max-width: 380px) {
                    @apply min-h-[80px] max-h-[80px];
                }
            }

            .bottom-content {
                @media screen and (max-width: 450px) {
                    @apply min-w-[90vw];
                }
            }

            &.swiper-slide-next,
            &.swiper-slide-prev {
                .image-wrap {
                    img {
                        filter: none;
                    }
                }
            }

            &.swiper-slide-next {
                @media screen and (max-width: 768px) {
                    .image-wrap {
                        &::after {
                            background: rgb(4, 21, 62);
                            background: linear-gradient(
                                -90deg,
                                rgba(4, 21, 62, 1) 0%,
                                rgba(4, 21, 62, 0.95) 75%,
                                rgba(4, 21, 62, 0) 100%
                            );
                            @apply right-0 opacity-100;
                        }
                    }
                }
            }

            &.swiper-slide-prev {
                @media screen and (max-width: 768px) {
                    .image-wrap {
                        &::after {
                            background: rgb(4, 21, 62);
                            background: linear-gradient(
                                -270deg,
                                rgba(4, 21, 62, 1) 0%,
                                rgba(4, 21, 62, 0.95) 75%,
                                rgba(4, 21, 62, 0) 100%
                            );

                            @apply left-0 opacity-100;
                        }
                    }
                }
            }

            &.swiper-slide-active {
                @apply relative mt-0;

                .bottom-content {
                    @apply opacity-100 pointer-events-auto;
                }

                .image-wrap {
                    @apply opacity-100;

                    img {
                        transform: scale(1);
                        filter: none;
                    }

                    &::after {
                        @apply hidden;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            @apply relative block;

            &::before {
                @apply w-[20%] h-[30%] absolute left-[-13%] z-30 top-0 opacity-100;

                content: "";

                background: rgb(4, 21, 62);
                background: linear-gradient(
                    -270deg,
                    rgba(4, 21, 62, 1) 0%,
                    rgba(4, 21, 62, 0.95) 75%,
                    rgba(4, 21, 62, 0) 100%
                );
            }

            &::after {
                @apply w-[20%] h-[30%] absolute right-[-13%] z-30 top-0 opacity-100;

                content: "";

                background: rgb(4, 21, 62);
                background: linear-gradient(
                    -90deg,
                    rgba(4, 21, 62, 1) 0%,
                    rgba(4, 21, 62, 0.95) 75%,
                    rgba(4, 21, 62, 0) 100%
                );
            }
        }
    }

    &__swiper-bg-active-img {
        top: 9%;
    }

    &__swiper-bg-active-img-middle {
        top: -28%;

        img {
            max-width: 75%;
            margin: auto;
        }

        @media screen and (max-width: 1024px) {
            top: -12%;
        }

        @media screen and (max-width: 550px) {
            top: -5%;
        }
    }

    &__swiper-pagination {
        bottom: var(--swiper-pagination-bottom) !important;

        .swiper-pagination-bullet {
            @apply w-4 h-4 border-2 border-blue opacity-100 bg-transparent;

            &.swiper-pagination-bullet-active {
                @apply bg-blue;
            }
        }

        @media screen and (max-width: 800px) {
            bottom: var(--swiper-pagination-bottom, -25px) !important;
        }
    }

    &__bottom-table {
        .table-col {
            &:nth-child(1) {
                .col-content {
                    @apply rounded-s-10px;
                }
            }

            &:nth-child(2) {
                .col-content {
                    @apply relative;

                    &::before {
                        @apply absolute left-0 bottom-0 my-auto top-0 h-[55%] w-[1px] bg-white;
                        content: "";
                    }

                    &::after {
                        @apply absolute right-0 bottom-0 my-auto top-0 h-[55%] w-[1px] bg-white;
                        content: "";
                    }
                }
            }

            &:last-child {
                .col-content {
                    @apply rounded-e-10px;
                }
            }
        }
    }

    @media screen and (max-width: 1650px) {
        .page-title {
            @apply mb-10;
        }

        .bottom-table {
            @apply my-8;
        }

        .suitable-for {
            @apply text-[0.8rem] leading-[1.3];
        }

        .boxes-chooser__swiper-bg-active-img-middle {
            @apply -top-[26%];
        }
    }
}
