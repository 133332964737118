.whats-needed-to-rent {
    background: rgb(255, 255, 255);
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(221, 221, 221, 1) 100%
    );

    &__bottom-contacts {
        a {
            @apply text-pageTitle text-blue font-semibold no-underline;

            text-decoration-thickness: 2px;

            &:hover {
                @apply underline;
            }

            @media only screen and (max-width: 1024px) {
                @apply text-3xl;
            }
        }
    }

    @media screen and (min-width: 1600px) {
        &__blocks-wrapper {
            @apply max-w-[75%] mx-auto;
        }
    }

    @media screen and (min-width: 1750px) {
        &__blocks-wrapper {
            @apply max-w-[75%] mx-auto;
        }
    }
}
