.global-link-component {
    @apply relative;

    &:not(.no-hover) {
        &::after {
            @apply w-full h-full absolute left-0 top-0 opacity-0;

            box-shadow: 0 0 25px #27e3fc;
            transition: opacity 0.5s ease-in-out;
            content: "";
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }
}
