#floatingCookies {
    bottom: 45px;
    left: 15px;
    line-height: 0;
    opacity: 1;
    padding: 10px;
    position: fixed;
    transition: all .5s ease;
    z-index: 9998;
    width: 4rem;
    height: 4rem;
}