.opening-carousel {
    &__hexagon-image {
        @media screen and (max-width: 1279px) {
            margin-right: inherit;
        }
    }

    &__single-slide-description-wrap {
        strong {
            color: white;
        }
    }

    &__image-wrap {
        &--0,
        &--1 {
            left: 0;
            right: 0;
            max-width: 63%;
            top: 16%;
            margin: auto;

            /* @media screen and (max-width: 1600px) {
                top: -2%;
                right: 11%;
                max-width: 100%;
            }
 */
            @media screen and (max-width: 1279px) {
                max-width: 65%;
                left: 0;
                right: 0;
                top: 12%;
                margin: auto;
            }

            @media screen and (max-width: 550px) {
                max-width: 55%;
                top: 22%;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        &--2 {
            top: 6.5%;
            left: 0;
            right: 10.5%;
            max-width: 100%;
            margin: auto;
            overflow: hidden;

            img {
                @apply mx-auto;
            }

            @media screen and (max-width: 1279px) {
                right: 10.5%;
                top: 15%;
            }

            @media screen and (max-width: 800px) {
                top: 17%;
                right: 10.5%;
            }

            @media screen and (max-width: 550px) {
                top: 4%;
                right: 15.5%;
                max-width: 75%;
            }
        }

        &--3 {
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            max-width: 35%;

            @media screen and (max-width: 1600px) {
                top: -3%;
                max-width: 36%;
            }

            @media screen and (max-width: 1400px) {
                top: 0;
            }

            @media screen and (max-width: 1279px) {
                max-width: 33%;

                top: 5%;
                left: 0;
                right: 0;
                margin: auto;
            }

            @media screen and (max-width: 1024px) {
                max-width: 33%;

                top: 5%;
                left: 0;
                right: 0;
                margin: auto;
            }

            @media screen and (max-width: 600px) {
                max-width: 32%;
                top: 0;
            }

            @media screen and (max-width: 550px) {
                top: 15%;
                max-width: 26%;
            }
        }

        &--4 {
            top: 0;
            right: 0;
            bottom: 0;
            left: -12%;
            margin: auto;
            max-width: 80%;

            @media screen and (max-width: 1600px) {
                left: -6%;
            }

            @media screen and (max-width: 1400px) {
                top: -5%;
            }

            @media screen and (max-width: 1279px) {
                top: 2%;
                left: 0;
                right: 0;
                margin: auto;
            }

            @media screen and (max-width: 1024px) {
                top: 5%;
                left: 0;
                right: 0;
                margin: auto;
            }

            @media screen and (max-width: 600px) {
                top: 0;
            }

            @media screen and (max-width: 550px) {
                top: 0;
                max-width: 55%;
            }
        }

        &--5 {
            top: 13%;
            right: 0;
            left: 0;
            margin: auto;
            max-width: 55%;

            @media screen and (max-width: 1279px) {
                left: 0;
                right: 0;
                margin: auto;
            }

            @media screen and (max-width: 1024px) {
                left: 0;
                right: 0;
                margin: auto;
            }

            @media screen and (max-width: 550px) {
                max-width: 50%;
            }
        }
    }

    &__hexagon-image-bottom {
        bottom: 5%;
        right: 0;
        left: 4%;
    }

    &__first-slide-logo {
        @apply transform-gpu;

        &:hover {
            filter: drop-shadow(0 0 30px #27e3fc);
        }
    }

    &__news-ribbon {
        inset: 0 auto auto 0;
        transform-origin: 100% 0;
        transform: translate(22.3%) rotate(-25deg);
        position: fixed;
        box-shadow: 0 0 0 999px #0096d6;
        clip-path: inset(0 -100%);
        z-index: 2;
        padding: 5px;
        font-size: 24px;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        opacity: 0;

        @apply bg-blue;
    }

    &__zero-slide-circles {
        @apply flex items-center justify-center;

        width: 100px !important;
        height: 100px !important;

        @keyframes droplet {
            0% {
                opacity: 1;
                width: 4px;
                height: 4px;
            }
            30% {
                opacity: 1;
            }
            100% {
                opacity: 0;
                width: 100px;
                height: 100px;
            }
        }

        div {
            @apply border-2 border-blue-light rounded-full absolute;

            width: 1%;
            height: 1%;
            animation: 8s droplet infinite;

            &:nth-child(2) {
                animation-delay: 1000ms;
            }

            &:nth-child(3) {
                animation-delay: 2000ms;
            }

            &:nth-child(4) {
                animation-delay: 3000ms;
            }

            &:nth-child(5) {
                animation-delay: 4000ms;
            }
        }

        &:hover {
            > div {
                animation-duration: 2s;
            }
        }
    }

    &__zero-slide-partials {
        top: 26%;
    }

    &__slide-video {
        @apply z-[10000001] fixed left-[50%] top-[50%] bottom-[50%] right-[50%] items-center justify-center bg-black;

        video {
            @apply object-contain lg:object-cover m-0 w-full h-full;
        }

        &.videoPlaying {
            animation: showVideo 2s forwards;
            @apply flex;
        }

        &.videoHiding {
            animation: hideVideo 2s forwards;
        }

        .closeVideo {
            @apply absolute top-5 right-5 w-10 h-10 cursor-pointer pointer-events-auto z-[10000002];

            svg {
                transition: fill 200ms ease-in-out;
            }

            &:hover {
                svg {
                    @apply fill-blue;
                }
            }
        }
    }

    @keyframes showVideo {
        0% {
            left: 50%;
            right: 50%;
            top: 20%;
            bottom: 20%;
            opacity: 0;
        }
        50% {
            left: 0;
            top: 20%;
            right: 0;
            bottom: 20%;
            opacity: 1;
        }
        100% {
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }
    }

    @keyframes hideVideo {
        0% {
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }
        50% {
            left: 0;
            top: 20%;
            right: 0;
            bottom: 20%;
            opacity: 1;
        }
        100% {
            left: 50%;
            right: 50%;
            top: 20%;
            bottom: 20%;
            opacity: 0;
        }
    }

    &__hexagon-text {
        left: 0;
        right: 0;
        bottom: 24%;

        @media screen and (max-width: 1279px) {
            right: 0;
            left: 0;
            margin: auto;
        }
    }

    &__second-slide-partials,
    &__third-slide-partials,
    &__fourth-slide-partials {
        a {
            svg {
                transition: all 0.5s ease-in-out;
            }

            &:hover {
                svg {
                    @apply text-blue-light;
                    filter: drop-shadow(1px 1px 8px #5cbae9);
                }
            }
        }
    }

    &__first-slide-partials {
        @media screen and (max-width: 2580px) {
            top: 43.5%;
        }

        @media screen and (max-width: 1950px) {
            top: 44%;
        }
    }

    &__first-slide-logo {
        @media screen and (max-width: 2580px) {
            svg {
                width: 100px;
                height: auto;
            }
        }

        @media screen and (max-width: 1950px) {
            svg {
                width: 87px;
                height: auto;
            }
        }

        @media screen and (max-width: 1750px) {
            svg {
                width: 80px;
                height: auto;
            }
        }

        @media screen and (max-width: 1550px) {
            svg {
                width: 64px;
                height: auto;
            }
        }

        @media screen and (max-width: 1350px) {
            svg {
                width: 60px;
                height: auto;
            }
        }
    }

    &__second-slide-phone-shapes {
        top: 2%;
        z-index: 40;
        mix-blend-mode: plus-lighter;
        right: -10%;
    }

    &__second-slide-partials,
    &__third-slide-partials,
    &__fourth-slide-partials {
        top: 30%;
    }

    &__swiper-pagination {
        bottom: var(--swiper-pagination-bottom, 40px) !important;

        .swiper-pagination-bullet {
            @apply w-4 h-4 border-2 border-blue opacity-100 bg-transparent;

            &.swiper-pagination-bullet-active {
                @apply bg-blue;
            }
        }

        @media screen and (max-width: 800px) {
            bottom: var(--swiper-pagination-bottom, 8px) !important;
        }
    }

    @media screen and (max-width: 1279px) {
        .mobile-overlay {
            background: rgb(22, 28, 48);
            background: linear-gradient(
                -180deg,
                rgba(22, 28, 48, 1) 0%,
                rgba(4, 36, 88, 1) 60%,
                rgba(0, 0, 0, 1) 100%
            );
            opacity: 0.66;
        }

        &__single-slide-right-image-col {
            margin: auto;
        }
    }

    @media screen and (max-width: 1600px) {
        &__opening-swiper {
            max-height: 91vh !important;
            min-height: inherit !important;
        }

        /* &__single-slide-right-image-col {
            width: 37% !important;
        } */

        &__slide-inner-content-wrap {
            @apply space-x-60 #{!important};
        }

        &__single-slide-left-content-col {
            @apply mb-16 #{!important};
        }
    }

    @media screen and (max-width: 1279px) {
        &__opening-swiper {
            max-height: inherit !important;
            min-height: inherit !important;
        }

        /* &__single-slide-right-image-col {
            @apply self-center mt-0;

            width: 34% !important;
        } */

        &__slide-inner-content-wrap {
            @apply space-x-0 items-center justify-center #{!important};
        }

        &__single-slide-left-content-col {
            @apply mb-0 #{!important};
        }
    }

    @media screen and (max-width: 850px) {
        /* &__single-slide-right-image-col {
            @apply mt-auto;

            width: 58% !important;
        } */

        &__swiper-next,
        &__swiper-prev {
            @apply flex #{!important};

            &::after {
                @apply hidden;
            }
        }

        &__slide-bg-image-0 {
            transform: scale(2.25);
            margin-top: 33%;
        }
    }

    @media screen and (max-width: 680px) {
        /*  &__single-slide-right-image-col {
            @apply mt-auto;

            width: 83% !important;
        } */

        &__news-ribbon {
            inset: 0 auto auto 0;
            transform-origin: 100% 0;
            transform: translate(22.3%) rotate(-25deg) !important;
            position: fixed;
            box-shadow: 0 0 0 999px #0096d6;
            clip-path: inset(0 -100%);
            z-index: 2;
            padding: 5px;
            font-size: 14px;
        }
    }

    @media screen and (max-width: 550px) {
        &__hexagon-text {
            @apply hidden;
        }

        &__hexagon-image {
            width: 75%;
            margin-left: 0;
            margin-right: 0;
            align-self: center;
        }

        /* &__single-slide-right-image-col {
            margin-top: -10%;
            margin-bottom: -10%;
            width: 100%;
        } */

        &__slide-bg-image-0 {
            transform: scale(2.25);
            margin-top: 93%;
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: var(--swiper-navigation-top-offset, 57%) !important;
        }

        &__single-slide-right-image-col {
            @apply mt-auto mb-0 #{!important};
        }

        &__news-ribbon {
            transform: translate(-29.3%) rotate(-45deg) !important;
            font-size: 12px;
            padding: 2px;
        }
    }

    @media screen and (max-width: 385px) {
        &__news-ribbon {
            font-size: 12px;
        }
    }
}
