.gold-fever {
    &__golds-swiper {
        .swiper-slide {
            height: auto !important;

            .bottom-content {
                @apply opacity-0 duration-300 transition-all;
            }

            .image-wrap {
                @apply opacity-40 min-h-[325px] max-h-[325px] flex items-center;

                img {
                    @apply transition-all duration-300 ease-in-out;

                    filter: blur(2px);

                    transform: scale(0.8);
                    margin: auto;
                }

                @media screen and (max-width: 1600px) {
                    @apply min-h-[300px] max-h-[300px];
                }

                @media screen and (max-width: 1280px) {
                    @apply min-h-[215px] max-h-[215px];
                }

                @media screen and (max-width: 801px) {
                    @apply min-h-[350px] max-h-[350px];
                }

                @media screen and (max-width: 760px) {
                    @apply min-h-[290px] max-h-[290px];
                }

                @media screen and (max-width: 550px) {
                    @apply min-h-[245px] max-h-[245px];
                }

                @media screen and (max-width: 450px) {
                    @apply min-h-[185px] max-h-[185px];
                }

                @media screen and (max-width: 380px) {
                    @apply min-h-[150px] max-h-[150px];
                }
            }

            .bottom-content {
                @media screen and (max-width: 450px) {
                    @apply min-w-[90vw];
                }
            }

            .image-wrap {
                img {
                    transform: scale(0.6);
                }
            }

            &.swiper-slide-next,
            &.swiper-slide-prev {
                .image-wrap {
                    img {
                        filter: none;
                        transform: scale(0.8);
                    }
                }
            }

            &.swiper-slide-next-next {
                .image-wrap {
                    img {
                        @apply -ml-[45px];
                    }
                }
            }

            &.swiper-slide-previous-previous {
                .image-wrap {
                    img {
                        @apply -mr-[45px];
                    }
                }
            }

            &.swiper-slide-active {
                @apply relative mt-0;

                .bottom-content {
                    @apply opacity-100;
                }

                .image-wrap {
                    @apply opacity-100;

                    img {
                        transform: scale(1);
                        filter: none;
                        margin: auto !important;
                    }
                }
            }
        }
    }

    &__swiper-bg-active-img {
        top: 27%;
    }

    &__swiper-bg-active-img-middle {
        top: -7%;

        img {
            max-width: 75%;
            margin: auto;
        }
    }

    &__swiper-pagination {
        bottom: var(--swiper-pagination-bottom) !important;

        .swiper-pagination-bullet {
            @apply w-4 h-4 border-2 border-blue opacity-100 bg-transparent;

            &.swiper-pagination-bullet-active {
                @apply bg-blue;
            }
        }

        @media screen and (max-width: 800px) {
            bottom: var(--swiper-pagination-bottom, -25px) !important;
        }
    }
}
