.summary::marker {
    display: none;
}

#details[open] summary img {
    animation: rotate 0.3s linear forwards;
}

#details summary img {
    animation: rotateBack 0.3s linear forwards;
}

.faq {
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(221, 221, 221, 1) 100%
    );
}

/* Hide the default arrow */
details summary::-webkit-details-marker {
    display: none;
}

/* Rotate the arrow when details is open */
details[open] summary::before {
    transform: rotate(90deg);
}

.animated-details {
    height: auto;
    overflow: hidden;
}

.animated-details > .content {
    opacity: 0;
    transition: opacity 0.75s ease-in-out;

    @apply pb-12;

    p {
        @apply my-0;
    }
}

.animated-details[open] > .content {
    opacity: 1;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes rotateBack {
    0% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@media (max-width: 1280px) {
    .hexagon-faq {
        display: none;
    }
}
