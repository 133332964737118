.text__content li::marker {
    color: #0096D6;
}

.content__site {
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(221,221,221,1) 100%);
}

.content__site ul ul {
    list-style-type: circle !important;
}

.content__site ul ul ul {
    list-style-type: none !important;
    margin-left: -25px;
}

.content__site ul ul ul li::before {
    content: "-";
    color: #0096D6;
    margin-right: 15px;
}


@media (max-width: 1280px) {
    .hexagon-text {
        display: none;
    }
}