.page-banner {
    &__image-gradient {
        background: rgb(4, 21, 62);
        background: linear-gradient(
            180deg,
            rgba(4, 21, 62, 1) 0%,
            rgba(4, 21, 62, 1) 100%
        );
    }

    &__image-glow {
        top: -30%;
    }

    &__bigTitle {
        -webkit-text-stroke: 1px #0096d6;
        color: transparent;
        opacity: 0.45;
        filter: drop-shadow(0px 0px 5px #56cff4);

        @media screen and (max-width: 350px) {
            font-size: 3.15rem !important;
        }
    }

    &__content-col {
        .content-wrap {
            strong,
            b {
                @apply text-current font-semibold;
            }
        }
    }

    &__swiper-pagination {
        bottom: var(--swiper-pagination-bottom, 40px) !important;

        .swiper-pagination-bullet {
            @apply w-4 h-4 border-2 border-blue opacity-100 bg-transparent;

            &.swiper-pagination-bullet-active {
                @apply bg-blue;
            }
        }

        @media screen and (max-width: 800px) {
            bottom: var(--swiper-pagination-bottom, 8px) !important;
        }
    }

    &__single-slide {
        @apply h-auto #{!important};
    }

    &__background {
        &.image-on-top {
            .page-banner__image-bottom {
                @apply right-[6%] bottom-[13%];

                @media screen and (max-width: 1920px) {
                    @apply right-[2%];
                }

                @media screen and (max-width: 1700px) {
                    @apply -right-[1%];
                }

                @media screen and (max-width: 1600px) {
                    @apply -right-[2%];
                }

                @media screen and (max-width: 1500px) {
                    @apply -right-[3%];
                }

                @media screen and (max-width: 1400px) {
                    @apply -right-[7%];
                }

                @media screen and (max-width: 1200px) {
                    @apply -right-[12%];
                }

                @media screen and (max-width: 1024px) {
                    @apply hidden;
                }
            }

            .page-banner__image-glow {
                @apply -top-[20%] right-[2%];

                @media screen and (max-width: 1920px) {
                    @apply -top-[11%] right-[3%];
                }

                @media screen and (max-width: 1600px) {
                    @apply right-[1%];
                }

                @media screen and (max-width: 1500px) {
                    @apply -right-[2%];
                }

                @media screen and (max-width: 1400px) {
                    @apply -right-[5%];
                }

                @media screen and (max-width: 1400px) {
                    @apply -right-[11%];
                }

                @media screen and (max-width: 1024px) {
                    @apply -bottom-[23%] right-[1%];
                }

                @media screen and (max-width: 700px) {
                    @apply -bottom-[26%] right-[1%];
                }

                @media screen and (max-width: 500px) {
                    @apply -bottom-[36%] right-[1%];
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        &__single-banner-wrap {
            &.image-bigger-on-mobile {
                .container-fluid {
                    @apply min-h-inherit;
                }
            }
        }

        &__image-col {
            &.image-bigger-on-mobile {
                @apply h-auto;

                .image-wrapper {
                    img {
                        @apply max-w-inherit;
                    }
                }
            }
        }
    }

    @keyframes scroll {
        0% {
            transform: translateY(0);
        }
        30% {
            transform: translateY(75px);
        }
    }

    svg #wheel {
        animation: scroll ease 2.5s infinite;
    }
}
