@import "../abstracts/mixins";

.valuables-carousel {
    scroll-padding-top: 0px;

    &__desktop-row {
        &--1 {
            column-gap: 28%;

            .item-even {
                .main-image {
                    max-width: 68%;
                }
            }
        }

        &--2 {
            column-gap: 42%;
        }

        &--3 {
            column-gap: 28%;
        }
    }

    &__desktop-image-circles {
        @apply w-full h-full absolute top-0 left-0 flex items-center justify-center;

        &::after {
            @apply rounded-full z-20 bg-purple-darkest absolute block;

            width: 85%;
            height: 85%;
            content: "";
        }

        &::before {
            @apply rounded-full absolute block bg-purple-darker;

            z-index: 21;
            width: 73%;
            height: 73%;
            content: "";
        }
    }

    &__desktop-image-wrapper,
    &__mobile-image-wrapper {
        .blends-wrapper {
            .bottom-bg {
                @apply rounded-full border border-blue-lighter relative w-full h-full border-opacity-40 z-20;
            }

            .top-bg {
                @apply absolute left-0 top-0 right-0 bottom-0 self-center bg-blue-dark;

                border-radius: 50px;
            }
        }
    }

    &__desktop-item {
        &.item-even {
            @apply text-right justify-end;

            &__title {
                @apply text-right;
            }

            &__description {
                @apply text-right;
            }
        }

        &.item-odd {
            @apply text-left justify-start flex-row-reverse;

            &__title {
                @apply text-left;
            }

            &__description {
                @apply text-left;
            }
        }

        &__description {
            p {
                @apply my-0;
            }
        }

        .top-left-shape {
            @apply absolute transition-all duration-300;

            mix-blend-mode: color-dodge;

            img {
                right: 0;
                position: absolute;
                top: -3%;
                left: -36%;
                width: 130px;
                transform: rotate(-32deg);
                mix-blend-mode: color-dodge;
            }
        }

        .bottom-left-shape {
            @apply absolute transition-all duration-300;

            mix-blend-mode: color-dodge;

            img {
                position: absolute;
                bottom: -23%;
                right: -25%;
                transform: rotate(-29deg);
                width: 130px;
                mix-blend-mode: color-dodge;
            }
        }

        &.item-index-2 {
            .main-image {
                @apply -bottom-1 -left-3;
            }
        }

        &.item-index-3 {
            .main-image {
                max-width: 75%;

                @apply rotate-12 -top-2 -right-3;
            }
        }

        &.item-index-4 {
            .main-image {
                @apply -left-6;
            }
        }

        &.item-index-5 {
            .main-image {
                @apply rotate-12 -right-8;
            }
        }

        &.item-index-6 {
            .main-image {
                max-width: 60%;
            }
        }
    }

    &__middle-image {
        @apply absolute left-0 top-0 w-full h-full items-center justify-center;

        .box-image {
            @apply mx-auto -mb-56 pl-8;

            z-index: 3;
        }
    }

    &__background-svg {
        svg {
            @apply w-full h-auto block;
        }
    }

    @media screen and (min-width: 2000px) {
        &__background-svg {
            .svg-wrapper {
                max-width: 20% !important;
            }
        }
    }

    @media only screen and (max-width: 1650px) {
        &__desktop-description-wrapper {
            h3 {
                @apply text-base;
            }
        }

        &__carousel-item__description {
            @apply text-[0.8rem] leading-[1.3];
        }

        &__desktop-image-wrapper {
            @apply w-[115px] h-[115px];

            .main-image {
                @apply max-w-[75%];
            }

            .blends-wrapper {
                @apply w-[115px] h-[115px];
            }
        }

        &__desktop-item {
            &.item-index-2 {
                .main-image {
                    @apply bottom-[inherit];
                }
            }

            .top-left-shape,
            .bottom-left-shape {
                @apply w-[115px] h-[115px];
            }
        }
    }

    @media only screen and (max-width: 1280px) {
        &__middle-image {
            @apply w-4/12 -top-8 left-0 right-0 mx-auto;
        }

        &__carousel-item__description {
            @apply text-sm leading-[1.3];
        }
    }

    @media only screen and (max-width: 1024px) {
        &__carousel-row {
            &--1 {
                column-gap: 15%;
            }

            &--2 {
                column-gap: 35%;
            }
            &--3 {
                column-gap: 15%;
            }
        }

        &__middle-image {
            @apply w-4/12 -top-8 left-0 right-0 mx-auto;
        }

        .blends-wrapper {
            @apply rounded-full relative;

            .bottom-bg {
                @apply top-0 absolute;
            }

            .top-left-shape {
                left: -36%;
                mix-blend-mode: color-dodge;
                transform: rotate(-45deg);
            }

            .bottom-right-shape {
                right: -28%;
                bottom: -22%;
                mix-blend-mode: color-dodge;
                transform: rotate(-45deg);
            }
        }

        &__mobile-slider-single-item {
            @apply w-[120px] h-[120px] opacity-30;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;

            .main-image {
                max-width: 30%;
                transition: all 0.3s ease-in-out;
            }

            .blends-wrapper {
                max-width: 55%;
                max-height: 55%;
            }

            &.previous-item,
            &.next-item {
                @apply opacity-90 #{!important};

                .blends-wrapper {
                    max-width: 55%;
                    max-height: 55%;

                    .top-left-shape {
                        left: -20%;
                        mix-blend-mode: color-dodge;
                        transform: rotate(-45deg);
                    }

                    .bottom-right-shape {
                        right: -38%;
                        bottom: -13%;
                    }
                }
            }

            &.active {
                @apply opacity-100;

                .blends-wrapper {
                    .top-left-shape {
                        left: -28%;
                        mix-blend-mode: color-dodge;
                        transform: rotate(-45deg);
                    }

                    .bottom-right-shape {
                        right: -28%;
                        bottom: -12%;
                        mix-blend-mode: color-dodge;
                        transform: rotate(-45deg);
                    }
                }
            }

            &.previous-item,
            &.next-item {
                .mobile-image-wrapper {
                    .top-left-shape,
                    .bottom-left-shape {
                        transition: all 0.3s ease-in-out;
                        max-width: 75%;
                        max-height: 75%;
                    }
                }

                .main-image {
                    max-width: 37.5%;
                    transition: all 0.3s ease-in-out;
                }
            }

            &.active {
                .main-image {
                    max-width: 65%;
                }

                .blends-wrapper {
                    max-width: 98%;
                    max-height: 100%;
                }
            }
        }

        &__mobile-track {
            border: 1px solid transparent;
            border-radius: 50%;
            position: absolute;
            width: 100%;
            height: 100%;
        }

        &__mobile-slider-wrapper {
            /* 
            TODO: Rewrite to Tailwind.css
            */

            .container {
                box-sizing: border-box;
                position: relative;
                width: 100%;
                height: auto;
                padding: 0;
                margin: 0;
                overflow: hidden;
                padding: 50px;
            }
            .wrapper {
                position: relative;
                width: 100%;
                max-width: 500px;
                height: auto;
                margin: 0 auto;
                padding: 0;
            }
            #path-svg {
                position: relative;
                width: 100%;
                height: auto;
                z-index: 1;
            }
            .content {
                position: relative;
                top: 0;
                left: 0;
                /*width: 100%;*/
                /*height: 0;*/
                overflow: visible;
                /*padding-bottom: 84%;*/
                z-index: 40;
            }

            .content::before {
                float: left;
                padding-top: 84%;
                content: "";
            }

            .content::after {
                display: block;
                content: "";
                clear: both;
            }

            @media (max-width: 799px) {
                .wrapper {
                    max-width: 360px;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        &__middle-image {
            @apply w-4/12 -top-28 left-0 right-0 mx-auto;
        }

        .prev-button,
        .next-button {
            @apply bottom-[14%];
        }
    }

    @media screen and (max-width: 768px) {
        .prev-button,
        .next-button {
            @apply bottom-[18%];
        }
    }

    @media only screen and (max-width: 750px) {
        &__middle-image {
            @apply w-[36%] -top-[10rem] left-0 right-0 mx-auto;
        }

        &__background-svg {
            @apply -mt-4;
        }
    }

    @media only screen and (max-width: 600px) {
        &__middle-image {
            @apply w-[38%] -top-[10rem] left-0 right-0 mx-auto;
        }

        &__background-svg {
            @apply -mt-16;
        }
    }

    @media only screen and (max-width: 550px) {
        &__middle-image {
            @apply w-[50%] -top-[10.35rem] left-0 right-0 mx-auto;
        }
    }

    @media only screen and (max-width: 475px) {
        &__middle-image {
            @apply w-[70%] -top-[9.35rem] left-0 right-0 mx-auto;
        }

        &__background-svg {
            @apply -mt-12;
        }
    }
}
