.header {
    @apply transition-all ease-in-out duration-300;

    z-index: 1000;

    &__menu-toggle {
        button {
            svg {
                path {
                    @apply transition-all ease-in-out;
                }
            }
        }

        &.active {
            button {
                @apply shadow-lg;

                svg {
                    path {
                        &.hamburger {
                            @apply hidden;
                        }
                    }

                    path {
                        &.cross {
                            @apply block;
                        }
                    }
                }
            }
        }
    }

    &__account {
        span {
            &::after {
                @apply block px-2 border-b-2 border-blue transition-transform duration-300 ease-in-out;

                transform: scaleX(0);

                content: "";
            }
        }

        .icon-wrap {
            @apply transition-colors duration-300 ease-in-out;

            svg {
                @apply transition-colors duration-300 ease-in-out;

                path {
                    @apply transition-colors duration-300 ease-in-out;
                }
            }
        }

        &:hover {
            span {
                &::after {
                    transform: scaleX(1);
                }
            }

            .icon-wrap {
                @apply border-blue;

                svg {
                    @apply stroke-blue;

                    path {
                        @apply stroke-blue;
                    }
                }
            }
        }

        .icon-wrap {
            svg {
                @apply h-4;
            }
        }
    }

    &__account-responsive {
        .icon-wrap {
            svg {
                @apply stroke-black;

                path {
                    @apply stroke-black;
                }
            }
        }
    }

    &__nav-link,
    &__language-link {
        &::after {
            @apply block px-2 border-b-2 border-blue transition-transform duration-300 ease-in-out;

            transform: scaleX(0);

            content: "";
        }

        &:hover {
            &::after {
                transform: scaleX(1);
            }
        }

        &--active {
            @apply text-blue;

            &::after {
                transform: scaleX(1);
            }
        }

        @media only screen and (max-width: 1536px) {
            &::after {
                @apply w-full ml-0;
            }
        }
    }

    &__navbar--mobile {
        &::before {
            @apply top-0 absolute z-10 bg-blue-darker;

            content: "";
            height: 82px;
            width: 200vw;
            left: -50vw;
        }
    }
}
