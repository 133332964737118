.overlay {
    background: rgb(0, 5, 29);
    background: linear-gradient(
        180deg,
        rgba(0, 5, 29, 1) 0%,
        rgba(0, 42, 74, 1) 100%
    );
}

.background-image {
    background-image: url("../../img/contact-form/contact-form.png");
}

.contact {
    background-color: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(221, 221, 221, 1) 100%
    );
}

// FOR GOLD FORM

/* The container */
.container__input {
    position: relative;
    padding-left: 35px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container__input input {
    position: absolute;
    opacity: 0;
    top: 20px;
    left: 11px;
    cursor: pointer;
    height: 2px;
    width: 2px;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 22px;
    left: 0;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #969799;
    border-radius: 2px;
    cursor: pointer;
}

.container__input input:checked ~ .checkmark {
    background-color: #0095d6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container__input input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container__input .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2.8px 2.8px 0;
    transform: rotate(41deg);
}

// FOR GOLD FORM END

/* The container */
.container__input--contact {
    position: relative;
    padding-left: 35px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (max-width: 991px) {
        height: auto !important;
    }
}

/* Hide the browser's default checkbox */
.container__input--contact input {
    position: absolute;
    opacity: 0;
    top: 20px;
    left: 11px;
    cursor: pointer;
    height: 2px;
    width: 2px;
}

/* Create a custom checkbox */
.checkmark-contact {
    min-width: 18px;
    left: 0;
    height: 18px;
    margin-bottom: auto;
    margin-top: 2px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #599cbe;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-contact:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container__input--contact input:checked ~ .checkmark-contact:after {
    display: block;
    position: absolute;
    margin: auto;
}

/* Style the checkmark/indicator */
.container__input--contact .checkmark-contact:after {
    /*     left: 6px;
    top: 2px; */
    width: 6px;
    height: 11px;
    border: solid #599cbe;
    border-width: 0 2.8px 2.8px 0;
    transform: rotate(41deg);
}

// FOR CONTACT FORM

// FOR CONTACT FORM END

.contact__form .hidden {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.loading {
    color: #ffffff;
    animation-name: fade-in;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

.success {
    color: #ffffff;
    animation-name: fade-in;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

.error {
    color: #ffffff;
    animation-name: fade-in;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

.fade-in {
    animation-name: fade-in;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

.fade-out {
    animation-name: fade-out;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

// ANIMATIONS

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.notification__button button {
    box-shadow: 0 0 25px #27e3fc;
}

.notification__button__overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.notification__overlay__open {
    background: rgb(0, 5, 29) !important;
    background: linear-gradient(
        180deg,
        rgba(0, 5, 29, 1) 0%,
        rgba(0, 42, 74, 1) 100%
    ) !important;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9000;
    opacity: 0.9;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 640px) {
    .checkmark {
        top: 12px;
    }
}
