.contacts-repeater {
    background: rgb(255, 255, 255);
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(221, 221, 221, 1) 100%
    );

    &__single-block-description {
        p {
            @apply my-0;
        }

        a {
            @apply no-underline;

            &:hover {
                @apply underline;

                text-decoration-thickness: 1.5px;
            }
        }
    }
}
