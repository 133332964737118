.bank-compare {
    &__row {
        @apply text-left flex justify-center items-center text-white py-5 text-base bg-blue-dark3;
    }

    &__head-row {
        @apply text-left text-lg font-semibold flex justify-center items-center text-white py-4 uppercase;
    }

    .parameters-col {
        @apply relative z-20;

        .bank-compare__row,
        .bank-compare__head-row {
            @apply pl-14 text-left justify-start;
        }

        .bank-compare__head-row {
            @apply items-end #{!important};
        }

        .bank-compare__row {
            @apply rounded-s-10px;
        }
    }

    .bank-col {
        .bank-compare__row {
            @apply rounded-e-10px;
        }

        .bank-compare__head-row {
            @apply justify-end #{!important};
        }
    }

    &__column {
        @apply space-y-3;
    }

    .safe-col {
        @apply relative;

        &::after {
            @apply absolute z-20 rounded-20px w-full h-full left-0 top-0;

            background-color: rgba(#4093d0, 0.45);
            content: "";
        }

        .bank-compare__row,
        .bank-compare__head-row {
            span {
                @apply z-30 relative;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .safe-col {
            img {
                @apply py-0;
            }

            .bank-compare__row {
                @apply text-center;
            }
        }

        .parameters-col {
            .bank-compare__row,
            .bank-compare__head-row {
                @apply px-4 text-left justify-start #{!important};
            }
        }

        &__row {
            @apply px-4 #{!important};
        }
    }

    @media screen and (max-width: 500px) {
        &__head-row {
            @apply text-xs;
        }

        &__row {
            @apply text-xs;
        }

        .safe-col {
            img {
                max-width: 75%;
                margin: auto;
            }
        }

        .bank-col {
            img {
                max-width: 35%;
                margin: auto;
            }
        }
    }
}
