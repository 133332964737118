//HTML styles for REM units
html {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

section {
    scroll-padding-top: 80px;
    scroll-margin-top: 80px;
}

// Lozad
.lozad[data-src] {
    &:not(.no-fade) {
        transition: opacity 0.1s ease;
        opacity: 0;

        &[data-loaded="true"] {
            opacity: 1;
        }
    }
}
