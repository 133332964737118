.mascot {
    position: fixed;
    right: -30px;
    bottom: 110px;
    z-index: 99;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;

    canvas {
        width: 120px;
        position: relative;
        top: 16px;
    }

    &__button {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-align: center;
    }

    @media screen and (max-width: 550px) {
        transform: scale(0.9);
        right: -35px;
        bottom: 80px;
    }

    &--hide-on-mobile {
        @media screen and (max-width: 1024px) {
            @apply hidden;
        }
    }
}
