@use "./base/_core.scss";

//import Tailwind.css directives
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./components/footer.scss";
@import "./components/header.scss";
@import "./components/link.scss";
@import "./components/mascot.scss";
@import "./components/floating-cookie.scss";
@import "./blocks/valuables-carousel.scss";
@import "./blocks/opening-carousel.scss";
@import "./blocks/contacts-repeater.scss";
@import "./blocks/whats-needed-to-rent.scss";
@import "./blocks/documents-for-download.scss";
@import "./blocks/page-banner.scss";
@import "./blocks/bank-compare.scss";
@import "./blocks/company-managment.scss";
@import "./blocks/contact-form.scss";
@import "./blocks/faq.scss";
@import "./blocks/text.scss";
@import "./blocks/gold-fever.scss";
@import "./blocks/boxes-chooser.scss";
@import "./blocks/map.scss";
@import "./blocks/box-benefits.scss";
@import "./blocks/404.scss";
@import "./blocks/owners-structure.scss";
@import "./blocks/parallax-block.scss";
@import "./blocks/certificates-row.scss";
