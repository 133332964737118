/* @import "components/slick/slick";
@import "components/slick/slick-theme"; // if you need default theme */

.company-managment {
    &__thumbs-slider {
        .swiper-slide {
            height: auto !important;
            z-index: 50 !important;
            mix-blend-mode: luminosity;

            @apply bg-blue-darker;

            .image-wrap {
                mix-blend-mode: luminosity;

                img {
                    opacity: 0.8;
                }
            }

            &.swiper-slide-next {
                .image-wrap {
                    @apply opacity-60;

                    img {
                        filter: blur(2px);
                    }
                }
            }

            &.swiper-slide-prev {
                .image-wrap {
                    @apply opacity-60;

                    img {
                        filter: blur(2px);
                    }
                }
            }
        }
    }

    &__texts-slider {
        .swiper-slide {
            @apply transition-opacity ease-in-out duration-500 opacity-0 #{!important};

            &.swiper-slide-active {
                @apply opacity-100 #{!important};
            }
        }
    }

    &__thumbs-slider-bottom-gradient {
        @apply block w-full absolute bottom-0 left-0 pointer-events-none;

        background: rgb(255, 255, 255);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(4, 21, 62, 0.85) 50%,
            rgba(4, 21, 62, 1) 100%
        );
        height: 80%;
    }

    &__thumbs-slider-glare {
        @apply absolute right-0 top-0 bottom-0 left-0 pointer-events-none m-auto mix-blend-hard-light;

        top: -30%;
        left: 5%;

        img {
            @apply mx-auto;

            max-width: 70%;
        }

        @media screen and (max-width: 550px) {
            top: -55%;
            left: 17%;

            img {
                max-width: 100%;
            }
        }
    }

    &__swiper-pagination {
        bottom: var(--swiper-pagination-bottom, 0) !important;

        .swiper-pagination-bullet {
            @apply w-4 h-4 border-2 border-blue opacity-100 bg-transparent;

            &.swiper-pagination-bullet-active {
                @apply bg-blue;
            }
        }

        @media screen and (max-width: 800px) {
            bottom: var(--swiper-pagination-bottom, -25px) !important;
        }
    }
}
