.footer {
    /* place on the bottom */
    /*   position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1; */

    &__socials-link {
        img {
            @apply transition-colors ease-in-out duration-1000;
        }

        &:hover {
            img {
                filter: invert(47%) sepia(75%) saturate(2738%)
                    hue-rotate(168deg) brightness(90%) contrast(101%);
            }
        }
    }

    &__nav-link {
        &::after {
            @apply block px-2 border-b-2 border-blue transition-transform duration-300 ease-in-out;

            width: 100%;
            transform: scaleX(0);

            content: "";
        }

        &:hover {
            &::after {
                transform: scaleX(1);
            }
        }

        &--active {
            @apply text-blue font-semibold;

            &::after {
                transform: scaleX(1);
            }
        }

        @media only screen and (max-width: 1536px) {
            &::after {
                @apply w-full ml-0;
            }
        }
    }

    @media screen and (max-width: 350px) {
        overflow-x: hidden;
    }
}
