.documents-for-download {
    &__single-block {
        &::after {
            @apply w-full h-full rounded-10px absolute left-0 bottom-0 top-0 m-auto;

            content: "";
        }

        .front-face {
            @apply delay-150 z-10;
        }

        .back-face {
            @apply absolute left-0 top-0 w-full h-full opacity-0 bg-blue transition-all duration-700 ease-in-out z-10;
        }

        &:hover {
            .front-face {
                @apply opacity-0;
            }

            .back-face {
                @apply opacity-100;
            }
        }
    }

    @media screen and (min-width: 1600px) {
        &__documents-wrapper {
            @apply max-w-[85%] mx-auto;
        }
    }

    @media screen and (min-width: 1750px) {
        &__documents-wrapper {
            @apply max-w-[90%] mx-auto;
        }
    }
}
